<template>
  <div modal>
    <div class="dim"></div>
    <div class="inner" :style="{'margin-top': `${popupTop}px`}">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  data() {
    return {
      popupTop: 0,
    }
  },
  mounted() {
    this.updatePopupTop();
  },
  methods:{
    updatePopupTop() {
      this.popupTop = (document.documentElement.scrollTop || document.body.scrollTop);
    },
  }

}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[modal] { .fs(0); .f; .abs; .lt; z-index: 100; .tc;
  .dim { .f; .fix; .lt; background: rgba(0,0,0,0.7); }
  .inner { .wf; .abs; .lt(50%,50%); transform: translate(-50%,-50%); z-index: 10; .tc; .mt(100);
    .close { .wh(51,52); .contain('/images/mo/pop-x.png'); .abs; .lt(50%,-64); transform: translateX(-50%); }
  }
}
@media screen and(min-width:1024px) {
  [modal] {
    .inner { .w(600); }
  }
}
</style>